import merge from "lodash-es/merge"
import type { PaginateResult } from "mongoose"
import type { TicketType } from "./ticket.store"

export interface PumpReading {
  /** Pump description */
  description: string
  runtime?: number
  starts?: number
}

export interface Measurement {
  _id: string
  organization: string
  asset: string
  /** Through which the measurement was created */
  ticketType?: TicketType
  ticket?: string
  /** Should be required, but is not available in old data -> add to validator? */
  date?: string
  kwh1?: number
  kwh2?: number
  water?: number
  pumps: Array<PumpReading>
}

export interface CreateMeasurementPayload extends Pick<Measurement, "asset" | "ticket"> {}
export interface UpdateMeasurementPayload extends Pick<Measurement, "kwh1" | "kwh2" | "water" | "pumps" | "date"> {}

export const useMeasurementStore = defineStore("measurement", () => {
  /** @deprecated */
  const measurements = ref<Array<Measurement>>([])

  const getMeasurements = (pagination = true) => useCrudMethods<Measurement>("/measurements", measurements).readItems(new URLSearchParams({ pagination: String(pagination) }))

  const getMeasurementsByPageWithItemCount = async (queryParameters: Record<string, any> = {}): Promise<PaginateResult<Measurement>> => {
    const defaultFilter = {
      select: {
        "pumps": 1
      },
    }

    const combined = merge(defaultFilter, queryParameters)
    const query = getUrlSearchParams(combined)

    const { data } = await useSamApi<PaginateResult<Measurement>>(`/measurements?${query}`)
    return data
  }

  const getMeasurementById = (id: Measurement["_id"]) => useCrudMethods<Measurement>(`/measurements`, measurements).readItemById(id)
  const updateMeasurement = (id: Measurement["_id"], measurement: UpdateMeasurementPayload) => useCrudMethods<Measurement>(`/measurements`, measurements).updateItem(id, measurement)
  const createMeasurement = (assetId: string, ticketId?: string) => useCrudMethods<Measurement>(`/measurements`, measurements).createItem({ asset: assetId, ticket: ticketId })
  const deleteMeasurement = (id: Measurement["_id"]) => useCrudMethods<Measurement>(`/measurements`, measurements).deleteItem(id)

  return {
    measurements,
    getMeasurements,
    getMeasurementsByPageWithItemCount,
    getMeasurementById,
    updateMeasurement,
    createMeasurement,
    deleteMeasurement,
  }
})
